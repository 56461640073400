//export const Url ="https://www.tms.dzamtech.co.zw";

//export const Url ="https://afcuatserver.tms.co.zw";

//export const Url ="https://afcserver.tms.co.zw";

//export const Url ="http://127.0.0.1:7999";
//export const Url ="https://devserver.zb.pos-tms.co.zw";
export const Url ="https://liveserver.zb.pos-tms.co.zw";
//export const Url ="https://devserver.pos-tms.co.zw";

//export const Url ="https://afc-uat.pos-tms.co.zw";

//export const Url ="https://afcserver.pos-tms.co.zw";

//export const Url ="https://afcuatserver.pos-tms.co.zw";
