import React from 'react';
//import ProgressBar from "@ramonak/react-progress-bar";
import {Tabs,Tab,Form,Button,Card,Row,Container,Col,ListGroup,Modal} from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg,Msg,ConfirmMsg} from './pop';
import {showSpinDiag} from './spin';
import {MSGS} from './constants/msg';
import {EventAllowed} from './profilecfg';
import {ROLEOPS,USER_PROF,LoggedInUser} from './constants/constants';
import Dropzone from "dropzone";
import Papa from 'papaparse';
import flatpickr from 'flatpickr'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
class MerchantDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        id:0,
        mid : '',
        mname:'',
        maddr1:'',
        maddr2:'',
        maddr3:'',
        maddr4:'',
        currencycode:0,
        currencynumber:0,
        countrynumber:0,
        currs:[['USD','ZUR','ZWG','GHS','GBP','GBT'],[840,710,924,936,826,959],[716,716,716,716,716,716]],
        middisable:false,
        randid: 0,
        hideresults:true,
        merchList:[],
        isspin:false,
        roles:{},
        toggle:false,
        csvtoggle:false,
        branch:false,
        terminaltype:0,
        cards:[],
        hosts:[],
        promos:[],
        currlist:[],
        tgnames:[],
        cid:0,
        hid:0,
        pid:0,
        prog:0,
        showprog:false,
        didmount:false,
        hide:true,
        ismulticurr:false,
        islogin:false,
        iszgbt:false,
        isusd:false,
        iszig:false,
        iszwg:false,
        stime:'',
        etime:'',
        wday:'',
        opts: {enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true
        },
          iszinara:false,
          gtariff:'0',
          logouttime:'2',
          tgname:'',
      }
     
    }

    async componentDidMount() {
      
      /*window.onkeydown = (e)=>{
        if(e.ctrlKey === true){
          e.preventDefault();
        }
       }*/

       window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
       var p = flatpickr('.tmstime',{enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true});
       
      const {r} = JSON.parse(sessionStorage.getItem('roles'));
      console.log("Received roles: "+JSON.stringify(r));
      await this.GetRolesHandler();
      //this.setState({roles: });
      await this.getConfigGroups();
      console.log("###Received roles: "+JSON.stringify(this.state.roles));
      this.setState({didmount:true},()=>{
        console.log('merch details Component DID MOUNT!');
      });
   }

   async componentDidUpdate(prevProps, prevState) {
      console.log('Merch details Component DID UPDATE!')
      //this.setState({mid:this.props.mid});
      if(prevState.options !=null  && this.state.options == null){
          console.log('Previous options are null!! getting values')
          this.setState({roles: await this.GetRolesHandler()});
          await this.getConfigGroups();
          /*window.onkeydown = (e)=>{
            if(e.ctrlKey === true){
              e.preventDefault();
            }
           }*/

           window.onkeydown = (e)=>{
            if(e.ctrlKey === true || e.metaKey === true){
              if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
              e.preventDefault();
            }
           }
      }else{
          console.log('options already received');
          
      }




   }

   logouttimeHandler = (e)=>{
    this.setState({logouttime:e.target.value})
   }

   getConfigGroups = async ()=>{
    //todo: will activate later
    //await this.getTariffNamesHandler();
    await this.getCards();
    await this.getComms();
    await this.getPromogroups();   
   }
   getPromogroups = async ()=>{
    console.log("calling get promos here!!!");
      var url =Url+'/getpromogrps'
        Axios.post(url,{
           
        }).then((res)=>{
          console.log('PromosResponse received: '+res.data.msg);
          console.log("promolist: "+JSON.stringify(res.data.promos));
          if(res.data.msg==='ok'){
           this.setState({promos:res.data.promos});
             // setPromoGroupList(res.data.promos);
          }
        });
  }
   getComms = async ()=>{
    console.log("calling get cards here!!!");
    var url =Url+'/getcommsgrps'
      Axios.post(url,{
         
      }).then((res)=>{
        console.log('CardsResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
      
           this.setState({hosts:res.data.comms});

        }
      });
}

   getCards = async ()=>{
    console.log("calling get cards here!!!");
    var url =Url+'/getcardsgroup'
      Axios.post(url,{
         
      }).then((res)=>{
        console.log('CardsResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            this.setState({cards:res.data.cards});
        }
      });
}

   getServices = async ()=>{
    console.log("calling get services here!!!");
    var url =Url+'/getservicegrps'
      Axios.post(url,{
         
      }).then((res)=>{
        console.log('ServicesResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            //console.log('Cards ID Response received: '+res.data.id);
            //setServiceGroupList(res.data.services);
            this.setState({})
        }
      });
}
getTariffNamesHandler = async()=>{
  var url =Url+'/gettgnames';
  const data ={};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            console.warn("failed ot get tariff names here: "+res.data.pop)
         }else{
            console.log("refreshing Tariff group Name data: "+JSON.stringify(res.data.data));
            const list = res.data.data;
            if(list.length===0){
              ShowMsg("Please add Tariff Groups under Zinara Settings" );
            }
            this.setState({tgnames:res.data.data});
            
         }
});
}

   GetRolesHandler = async () =>{
    var data={roleid: sessionStorage.getItem('role')};
    var url =Url+'/getroles';
    console.log("geroles url: "+url);
 console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('profile data received OK!!');
       const r ={entries:res.data.roles[0],changes:res.data.roles[1],views:res.data.roles[2]};
       this.setState({roles:r},()=>{
        console.log("Received Roles: "+JSON.stringify(this.state.roles));   
       const ishide = !(EventAllowed(r.entries,ROLEOPS.USER) || EventAllowed(r.changes,ROLEOPS.USER) || EventAllowed(r.views,ROLEOPS.USER));
        this.setState({hide:ishide});
       });
      
     }else{
       this.clearHandler();
     }

   
    });
   }


    midHandler =(e)=>{
      this.setState({mid:e.target.value})
    }

    merchantnameHandler =(e)=>{
      this.setState({mname:e.target.value})
    }
    maddr1Handler =(e)=>{
      this.setState({maddr1:e.target.value})
    }
    maddr2Handler =(e)=>{
      this.setState({maddr2:e.target.value})
    }
    maddr3Handler =(e)=>{
      this.setState({maddr3:e.target.value})
    }
    maddr4Handler =(e)=>{
      this.setState({maddr4:e.target.value})
    }

    merchResultHandler = (e)=>{
        e.preventDefault();
      const idx = e.target.value;
      this.toggleModal();
      console.log('Selected Index: '+ idx);
      if(this.state.merchList.length > idx){
        const m = this.state.merchList[idx];
       this.setState({middisable:true});
           this.setState({id:m.id});
           this.setState({mid:m.mid});
           this.setState({mname:m.name});
           this.setState({maddr1:m.addr1});
           this.setState({maddr2:m.addr2});
           this.setState({maddr3:m.addr3});
           this.setState({maddr4:m.addr4});
           this.setState({cid:m.cid},()=>{
            this.checkifZinaraHandler();
          });
           this.setState({pid:m.pid});
           this.setState({branch:m.branch});
           this.setState({terminaltype:(m.branch===true)?"2":"1"});
           this.setState({hid:m.hid});
          this.setState({currencycode:m.currencycode});
          this.setState({currencynumber:m.currencynumber});
          this.setState({ismulticurr:m.multicurr});
          this.setState({islogin:m.islogin});
          this.setState({iszgbt:m.iszgbt});
          this.setState({iszig:m.iszig});
          this.setState({iszwg:m.iszwg});
          this.setState({isusd:m.isusd});
          this.setState({stime:m.stime});
          this.setState({etime:m.etime});
          this.setState({wday:m.wday});
          this.setState({gtariff:m.gtariff});
          this.setState({logouttime:m.logouttime});
         // ShowMsg("Download day: "+m.wday,true );
          this.setState({countrynumber:m.countrycode},()=>{
            //this.findHandler();  //not sure why I am getting data for merchant again here
          })

       }
    }

    generateRandom =()=>{
      const min = 1;
        const max = 100;
        const random = min + (Math.random() * (max - min));
        this.setState({randid: random })
    }

    findHandler=()=>{
      var url =Url+'/findmerch'
      if(!EventAllowed(this.state.roles.views,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_FIND,false)
        return
      }
      console.log('Doing find merch POST here!!!');
      const {mid,mname}=this.state;
      console.log('MDetails current mid: '+mid);
      if(mid==='' && mname===''){
        ShowMsg('Please Enter search data first!!',false);
        return;
      }
      
      Axios.post(url,{
        mid: this.state.mid,
        name:this.state.mname,
      }).then((res)=>{
        console.log('Find Response received: '+res.data.msg);
        if(res.data.msg==='ok' && res.data.data !== null){
          this.generateRandom();
            console.log('MID received: '+res.data.data.mid);
             /* this.props.getmid(res.data.data.id);
              if(res.data.services!=null){
              console.log("Balance Service: "+res.data.services.balance)
              var s= res.data.services;
                s.random = this.state.randid;
              this.props.getsdata(s);
            }*/
            console.log("Currency code: "+res.data.data.currencycode);
            sessionStorage.setItem('cmid',res.data.data.id);
            this.setState({middisable:true});
              this.setState({id:res.data.data.id});
              this.setState({mid:res.data.data.mid});
              this.setState({mname:res.data.data.name});
              this.setState({maddr1:res.data.data.addr1});
              this.setState({maddr2:res.data.data.addr2});
              this.setState({maddr3:res.data.data.addr3});
              this.setState({maddr4:res.data.data.addr4});
             this.setState({currencycode:res.data.data.currencycode})
             this.setState({currencynumber:res.data.data.currencynumber})
             this.setState({countrynumber:res.data.data.countrycode})

             this.setState({logouttime:res.data.data.logouttime});

             this.setState({cid:res.data.data.cid},()=>{
              this.checkifZinaraHandler();
            });
           this.setState({pid:res.data.data.pid});
           this.setState({hid:res.data.data.hid});
           this.setState({branch:res.data.data.branch});
           this.setState({terminaltype:(res.data.data.branch===true)?"2":"1"});
           this.setState({ismulticurr:res.data.data.multicurr});
           this.setState({islogin:res.data.data.islogin});
           this.setState({iszgbt:res.data.data.iszgbt});
          this.setState({iszig:res.data.data.iszig});
          this.setState({iszwg:res.data.data.iszwg});
          this.setState({isusd:res.data.data.isusd});
          this.setState({stime:res.data.data.stime});
          this.setState({etime:res.data.data.etime});
          this.setState({wday:res.data.data.wday});
          this.setState({gtariff:res.data.data.gtariff});
         // this.setState({wday:res.data.data.wday});
          //ShowMsg("Download day: "+res.data.data.wday,true );
        }else{
          ShowMsg('Merchant Record not found!!',false);
          //this.props.getmid(0);
          //this.props.getsdata(null);
          this.clearHandler();
        }
      });
    }

    findallHandler= async ()=>{
      const {isspin}=this.state;
      if(!EventAllowed(this.state.roles.views,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_FIND,false)
        return
      }
      await this.clearHandler();
      console.log('merchant: '+JSON.stringify(this.state));
       console.log('merchant id: '+this.state.mid+' name: '+this.state.mname);

       var data={mid: this.state.mid, name: this.state.mname};
          var url =Url+'/findallmerch';
       console.log('Doing Get users POST here!!!');
        this.setState({isspin:true});
       Axios.post(url,data).then((res)=>{

         console.log('User Find Response received: '+res.data.msg);

         this.setState({isspin:false});
         if(res.data.msg==='ok'){
             console.log('User list data received OK!!');
             if(res.data.mlist.length>0){
              console.log("merchant list: "+JSON.stringify(res.data.mlist));
               this.setState({merchList:res.data.mlist});
                this.setState({hideresults:false});
                this.toggleModal();
             }

         }else{
           this.setState({merchList:[]});
           console.log('merch find failed!!!')
           ShowMsg(res.data.pop,false);
         }
       });
    }

    validator=()=>{
      const {mid,mname,maddr1,maddr2,currencycode} = this.state;
      if(mid==='' || mname==='' || maddr1==='' || currencycode===0)
      return false;
      return true;
    }


    saveHandler=()=>{
      var url =Url+'/addmerch'
      const {roles,id} = this.state;
      if(id===0 && !EventAllowed(roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.TERMINAL_INSERT,false);
        return;
      }
    
      if(id > 0 && !EventAllowed(roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_EDIT,false);
        return;
      }
    
      if(this.state.id === 0 && !EventAllowed(this.state.roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_INSERT,false)
        return
      }
      if(this.state.id > 0 && !EventAllowed(this.state.roles.changes,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_EDIT,false)
        return
      }
      if(!this.validator()){
        ShowMsg('Please enter all fields!!',false);
        return;
      }

      const tmsdata ={
        mid:this.state.id,
        day:this.state.wday,
        stime:this.state.stime,
        etime:this.state.etime,
      }
      
      console.log('Doing Sign Up POST here!!! islogin: '+this.state.islogin+' wday:'+this.state.wday);
      Axios.post(url,{
        id: this.state.id,
        mid: this.state.mid,
        name:this.state.mname,
        addr1:this.state.maddr1,
        addr2:this.state.maddr2,
        addr3:this.state.maddr3,
        addr4:this.state.maddr4,
        addr5:this.state.maddr5,
        currencycode:this.state.currencycode,
        currencynumber:this.state.currencynumber,
        countrycode:this.state.countrynumber,
        sid:1,
        cid:this.state.cid,
        hid:this.state.hid,
        pid:this.state.pid,
        branch:this.state.branch,
        multicurr:this.state.ismulticurr,
        islogin:this.state.islogin,
        iszgbt:this.state.iszgbt,
        iszig:this.state.iszig,
        iszwg:this.state.iszwg,
        isusd:this.state.isusd,
        wday:this.state.wday,
        stime:this.state.stime,
        etime:this.state.etime,
        gtariff:this.state.gtariff,
        logouttime:this.state.logouttime ,
        tmsdata:tmsdata,
      }).then((res)=>{
        console.log('Response received: '+res.data.msg);
        console.log('MID received: '+res.data.id);
        if(res.data.msg==='ok'){
          sessionStorage.setItem('cmid',res.data.id);
          //this.props.getmid(res.data.id);
          var id = this.state.id;

          if(id>0)
          ShowMsg('Details Update was Successful!!',true);
          else
          ShowMsg('Registration was Successful!!',true);
          this.setState({id:res.data.id});
          //this.clearHandler(); <--- request from bank to stop this: 15042024
        }else{
         ShowMsg(res.data.pop,false);
        }
      });
    }
    clearHandler= async ()=>{
       // this.props.getsdata(null);
      //this.props.getmid(0);
      this.setState({id:0});
      this.setState({mid:''});
      this.setState({mname:''});
      this.setState({maddr1:''});
      this.setState({maddr2:''});
      this.setState({maddr3:''});
      this.setState({maddr4:''});
      this.setState({cid:0},()=>{
        this.checkifZinaraHandler();
      });
      this.setState({hid:0});
      this.setState({pid:0});
      this.setState({terminaltype:0});
      this.setState({currencycode:0});
      this.setState({currencynumber:0});
      this.setState({countrynumber:0});
      this.setState({middisable:false});
      this.setState({hideresults:true});
      this.setState({merchList:[]});
      this.setState({isspin:false});
      this.setState({branch:false});
      this.setState({branch:false});
      this.setState({showprog:false});
      this.setState({hide:false});
      this.setState({ismulticurr:false});
      this.setState({islogin:false});
      this.setState({iszinara:false});
      this.setState({iszgbt:false});
      this.setState({iszig:false});
      this.setState({iszwg:false});
      this.setState({isusd:false});
      this.setState({stime:''});
      this.setState({etime:''});
      this.setState({wday:''});
      this.setState({iszinara:false});
      this.setState({gtariff:'0'});
      this.setState({logouttime:'2'});
    }
    doDeleteMerchant = ()=>{
      var url =Url+'/deletemerch';
      console.log('Doing merchant delete POST here!!!');
      const {id}= this.state;
      Axios.post(url,{
        id:id
      }).then((res)=>{
        console.log('Delete Merch Response received: '+res.data.msg);
          console.log('MSG received: '+res.data.pop);
        if(res.data.msg==='ok'){
          ShowMsg(res.data.pop,true);
          this.clearHandler();
        }else{
          ShowMsg(res.data.pop,false);
        }
      });
    }
    deleteHandler =()=>{
      const {id} = this.state;
      if(id===0){
        ShowMsg("Please select Merch record To Delete First!!",false);
        return;
      }
      ConfirmMsg('Are you sure you want to delete Merchant?','After deleting this merchant all terminals related to this merchant will be left orphaned. ',this.doDeleteMerchant);
    }
    exportallHandler =()=>{
      var url =Url+'/exportmerchants';
      console.log('Doing Bulk Merchant Export POST here!!!');
      this.setState({showprog:true});
      Axios.post(url,{
    
      }).then((res)=>{
        console.log('Bulk Merch Export Response received: '+res.data.msg);
          console.log('MSG received: '+res.data.pop);
          this.setState({showprog:false});
        if(res.data.msg==='ok'){
          ShowMsg(res.data.pop,true);
        }else{
          ShowMsg(res.data.pop,false);
        }
      });
    }
    activateHandler=()=>{
      var url =Url+'/exportmerchant'
      console.log('Doing Merchant Export POST here!!!');
      if(this.state.mid===''){
        ShowMsg('Please Specify Merchant Data to Export!!',false);
        return;
      }
      Axios.post(url,{
        mid: this.state.mid,
      }).then((res)=>{
        console.log('Response received: '+res.data.msg);
          console.log('MSG received: '+res.data.pop);
        if(res.data.msg==='ok'){
          ShowMsg(res.data.pop,true);
        }else{
          ShowMsg(res.data.pop,false);
        }
      });
    }

    currencycodeHandler =(e)=>{
        this.setState({currencycode:e.target.value});
        console.log("currency code value: "+this.state.currencycode);
        var pos = e.target.value;
        console.log("currency code value: "+pos);
        if(pos>0){
        pos -=1;
        const dat ={currencynumber: this.state.currs[1][pos],countrynumber: this.state.currs[2][pos]};
        this.setState(dat);
      }

    }

    multicurrencyHandler =(e)=>{
      console.log("Current Checkbox State: "+e.target.checked);
      this.setState({ismulticurr:e.target.checked},()=>{
        //const k =!e.target.checked;
        //e.target.value=k;
        console.log("checkbox selected current value: "+this.state.ismulticurr);
      });
      
    }

    loginHandler =(e)=>{
      this.setState({islogin:e.target.checked},()=>{
        console.log("Cashier Login checkbox selected current value: "+this.state.islogin);
      })
    }

    findCard = async (val)=>{
      //ShowMsg("Cards size: "+this.state.cards.length,true);
      const list = this.state.cards;
      var i=0;
      //ShowMsg("Card Not found: ID: "+val+" "+JSON.stringify(list),true);
      for(i=0; i<list.length; i++){
        if(list[i].id+'' === val+''){
         // ShowMsg("Card found: "+JSON.stringify(list[i]),true);
          return list[i];
        }
      }
      
    }

    checkifZinaraHandler = async()=>{
      const id = this.state.cid;
      const c = await this.findCard(id);
      console.log("Selected Card: "+JSON.stringify(c));
      //ShowMsg("Card #2: "+JSON.stringify(c),true)
      if(c !== undefined){
        if(c.name.toLowerCase().indexOf('zinara')!==-1)
        this.setState({iszinara:true});
      else{
        this.setState({iszinara:false});
      }
    }
  }

    cardHandler = async (e)=>{
      const  id =e.target.value;
      this.setState({cid:e.target.value});
      const c =await this.findCard(id);
      console.log("Selected Card: "+JSON.stringify(c));
      //ShowMsg("Card #2: "+JSON.stringify(c),true)
      if(c !== undefined){
        if(c.name.toLowerCase().indexOf('zinara')!==-1)
        this.setState({iszinara:true});
      else{
        this.setState({iszinara:false});
      }
      }
      console.log("card value: "+this.state.cid);
    }

    hostHandler =(e)=>{
      this.setState({hid:e.target.value});
      console.log("host id value: "+this.state.hid);
    }

    promoHandler =(e)=>{
      this.setState({pid:e.target.value});
      console.log("promo id value: "+this.state.pid);
    }

    currencyHandler =(e)=>{
      const id = e.target.id;
      const c = e.target.checked;
      switch(id){
        case "zgbt":
          if(c===true)
          this.setState({iszgbt:true});
        else{
          this.setState({iszgbt:false});
        }
        break;
        case "usd":
          if(c===true)
          this.setState({isusd:true});
        else{
          this.setState({isusd:false});
        }
        break;
        case "zig":
          if(c===true)
          this.setState({iszig:true});
        else{
          this.setState({iszig:false});
        }
        break;
        case "zwg":
          if(c===true)
          this.setState({iszwg:true});
        else{
          this.setState({iszwg:false});
        }
        break;
        default:
      }

    }

    terminaltypeHandler =(e)=>{
      this.setState({terminaltype:e.target.value});
      if(e.target.value === "1" || e.target.value === "0")
      this.setState({branch:false});
      else
      this.setState({branch:true});
    }

    autoDownloadHandler = (e)=>{
      var id = e.target.id;
      var val = e.target.value;
      const txt="Selected component id:"+id+ "value: "+val;
      //ShowMsg(txt,false);
   // console.log("Selected component id:"+id+ "value: "+val);
      switch(id){
        case 'stime':
          this.setState({stime:val});
          break;
          case 'etime':
            this.setState({etime:val});
            break;
            case'wday':
            this.setState({wday:val});
            break;
          default:
      }
    }

    groupTariffHandler = (e)=>{
      var val = e.target.value;
      this.setState({gtariff:val});
    }

    toggleModal = ()=>{
      this.setState({toggle: !this.state.toggle});
    }

    togglecsv =()=>{
      this.setState({csvtoggle:!this.state.csvtoggle});
    }

    csvupload = ()=>{
      this.togglecsv();
      
    }

    getMerchantList = async ()=>{
      var url =Url+'/getmerchants';
    var data = {};
   var list;
    console.log('getting merchants POST here!!!');
  const mlist = await Axios.post(url,data).then((res)=>{
      console.log('Merchants Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Merchants received  OK!! received note: ');
          list = res.data.mids;
   console.log("received merch Datalist: "+JSON.stringify(list));
   return list
      }else{
        return [];
      }
    });
    return mlist;
    }

    getCurrencyCode = (cur)=>{
      console.log("Parsed currency code: "+cur);
      const curs= ['USD','ZUR','ZWL','GHS','GBP','ZIG','ZWG'];
      for(var i=0; i<curs.length; i++){
        if(curs[i].toLowerCase()===cur.toLowerCase())
        return (i+1)+"";
      }
      return -1+"";
    }

    getPromos = async ()=>{
      var url =Url+'/getpromogrps';
    var data = {};
   var list;
    console.log('getting promos POST here!!!');
  const mlist = await Axios.post(url,data).then((res)=>{
      console.log('Promos Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Promos received  OK!! received note: ');
          list = res.data.promos;
   console.log("received promo Datalist: "+JSON.stringify(list));
   return list
      }else{
        return [];
      }
    });
    console.log("result: "+JSON.stringify(mlist));
    return mlist;
    }

    gethosts = async()=>{
      var url =Url+'/getcommsgrps';
    var data = {};
   var list;
    console.log('getting comms POST here!!!');
  const mlist = await Axios.post(url,data).then((res)=>{
      console.log('Comms Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Comms received  OK!! received note: ');
          list = res.data.comms;
   console.log("received comms Datalist: "+JSON.stringify(list));
   return list
      }else{
        return [];
      }
    });
    console.log("result: "+JSON.stringify(mlist));
    return mlist;
    }

    getCardServices =async()=>{
      var url =Url+'/getservicegrps';
    var data = {};
   var list;
    console.log('getting cards POST here!!!');
  /*const mlist = await Axios.post(url,data).then((res)=>{
      console.log('Cards Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Cards received  OK!! received note: ');
          list = res.data.services;
   console.log("received cards Datalist: "+JSON.stringify(list));
   return list
      }else{
        return [];
      }
    });*/
    const {cards} = this.state;
    console.log("result: "+JSON.stringify(cards));
    return cards;
    }

    getTerminalType = async(ttype)=>{
      if(ttype.toLowerCase()==='retail')
      return false;
      return true;
    }

    processcsvdata = async (data)=>{
      this.togglecsv();
      this.setState({showprog:true},async ()=>{
//const mlist = await this.getMerchantList();
const plist = await this.getPromos();
console.log("promolist: "+JSON.stringify(plist));
const hlist = await this.gethosts();
console.log("hostlist: "+JSON.stringify(hlist));
const cardlist = await this.getCardServices();
//template format merchantid(prefer some code),name,addr1-5,currencycode,currencynumber,countrycode,servicesgrp,hostconfig,promoconfig,terminaltype
//console.log(`File added here: ${file.name}`);
var bulk=[];
var tmp={};
const size = data.length-1;
const title = data[0];

for(var i=1; i<size; i++){
  tmp={};
  tmp["id"]="0";
  tmp["sid"]="1";
  var found=false;
  var line =0;
  const res = Math.round(((i/size)*70));
  
  for(var j =0; j<title.length; j++){
    //console.log("current heading: "+title[j])
    if(title[j]=== "createdAt" || title[j] === "updatedAt" )
    continue;
    
    if(title[j]==="currencycode"){
      const cur = data[i][j]+"";
     const curid = this.getCurrencyCode(cur);
     //console.log("received curreid: "+JSON.stringify(curid));
     tmp[title[j]+""] = curid+"";
    }else if(title[j]==="servicesgrp"){
      //get cid value here and assign to tmp
      found=false;
      for(var k =0; k< cardlist.length; k++){
        //console.log("service from file: "+data[i][j].trim().toLowerCase()+" Service from DB: "+cardlist[k].name.toLowerCase());
        if(data[i][j].trim().toLowerCase()===cardlist[k].name.toLowerCase()){
        tmp["cid"]=cardlist[k].id+"";
        found=true;
        break;
        }
      }
      if(!found){
        ShowMsg('Please note that Services Group: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
          return;
      }
      
    }
    else if(title[j]==="hostconfig"){
      //get hid value here and assign to tmp
      //console.log("Commslist size: "+hlist.length);
      found=false;
      for(k=0; k<hlist.length; k++){
       // console.log("current K value: "+k);
       // console.log("csv comms: "+data[i][j].trim().toLowerCase()+" DB host: "+hlist[k].name.toLowerCase());
        if(data[i][j].toLowerCase()===hlist[k].name.toLowerCase()){
         tmp["hid"]=hlist[k].id+"";
         found=true;
         break;
        }                        
      }  
      if(!found){
        ShowMsg('Please note that Comms Config: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
          return;
      }          
    }else if(title[j]==="promoconfig"){
      //get pid value here and assign to tmp
      found=false;
      for(k =0; k< plist.length; k++){
        if(data[i][j].toLowerCase()===plist[k].name.toLowerCase()){
          tmp["pid"]=plist[k].id+"";
          found=true;
         break;
        }
        
      }
      if(!found){
        ShowMsg('Please note that Promo Config: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
          return;
      }
    }else if(title[j]==="terminaltype"){
        const ttype = await this.getTerminalType(data[i][j]);
        tmp["branch"]=ttype;
    }else if(title[j]==="merchantid"){
      tmp["mid"]=data[i][j];
  }
    else{
    tmp[title[j]+""]=data[i][j];
    } 
  } 
  console.log("tmp.name: "+tmp.name);
  bulk.push(tmp);
  //for (var member in tmp) delete tmp[member];  
}
//console.log("final formated data to send: "+JSON.stringify(bulk));
/*if(true)
return;*/
var url =Url+'/bulkaddmerch'
console.log('Doing Merchant bulk POST here!!!');
if(bulk.length===0){
  ShowMsg('Please use csv file with atleast one merchant!!',false);
  return;
}

Axios.post(url,bulk).then((res)=>{
  console.log('Response received: '+res.data.msg);
    console.log('MSG received: '+res.data.pop);
    
    this.setState({showprog:false},()=>{
      if(res.data.msg==='ok'){
        ShowMsg(res.data.pop,true);
      }else{
        ShowMsg(res.data.pop,false);
      }
    });
  
 
});
      });
      
    }
      


      render(){
        const {iszgbt,isusd,iszig,iszwg,hide,roles,isspin,hideresults,showprog,ismulticurr, stime,etime,iszinara,islogin}= this.state;
        const opts = {enableTime: true,
          noCalendar: true,
          dateFormat: "H:mm",
          parseDate: (datestr,format)=>{
            return moment(datestr,format,true).toDate();
          },
          formatDate: (date,format,locale)=>{
            return moment(date).format(format);
          },
          time_24hr: true};
        
       return(
         <div>
         <Card>
         <Card.Body>
         <Form>
         <Row>
         <Col  sm={3}>
         {/*<Form.Group>
         <Form.Label>Merchant ID</Form.Label>
         <Form.Control type="text" disabled={this.state.middisable} placeholder="New Merchant ID...." value={this.state.mid} onChange={this.midHandler}/>
         </Form.Group>*/}
         <Form.Group>
         <Form.Label>Merchant ID</Form.Label>
         <Form.Control type="text" placeholder="New Merchant ID...." value={this.state.mid} onChange={this.midHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Merchant Name</Form.Label>
         <Form.Control type="text" placeholder="Merchant Name...." value={this.state.mname} onChange={this.merchantnameHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Address Line1</Form.Label>
         <Form.Control type="text" placeholder="Address1...." value={this.state.maddr1} onChange={this.maddr1Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>HeartBeat Day</Form.Label>
         <Form.Select id='wday'  size="sm" onChange={this.autoDownloadHandler} value={this.state.wday} custom >
            <option>Select Day</option>
            <option value='0'>Everyday</option>
            <option value='1'>Sunday</option>
            <option value='2'>Monday</option>
            <option value='3'>Tuesday</option>
            <option value='4'>Wednesday</option>
            <option value='5'>Thursday</option>
            <option value='6'>Friday</option>
            <option value='7'>Saturday</option>
         </Form.Select>
         </Form.Group>
         <Form.Group hidden={!iszinara}>
         <Form.Label>Zinara Tariff Groups</Form.Label>
         <Form.Select id='gtariff' size="sm" onChange={this.groupTariffHandler} value={this.state.gtariff} custom >
            <option>Select Tariff Group</option>
            {
              
              this.state.tgnames.map((opt)=>(
                <option value={opt.id}>{opt.name}</option>
              ))
            }
           
         </Form.Select>
         </Form.Group>
          {showSpinDiag(isspin,'Searching record(s)')}
         </Col>
         <Col  sm={3}>
         <Form.Group>
         <Form.Label>Address Line2</Form.Label>
         <Form.Control type="text" placeholder="Address2...." value={this.state.maddr2} onChange={this.maddr2Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Address Line3</Form.Label>
         <Form.Control type="text" placeholder="Address3...." value={this.state.maddr3} onChange={this.maddr3Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Adrress Line4</Form.Label>
         <Form.Control type="text" placeholder="Address4...." value={this.state.maddr4} onChange={this.maddr4Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Heartbeat Start Time</Form.Label>
         <Flatpickr data-enable-time value={stime} options={opts} className='form-control' onChange={([stime])=>{
          this.setState({stime},()=>{
            this.setState({stime: moment(this.state.stime).format('HH:mm')});
           // ShowMsg(moment(this.state.stime).format('HH:mm'),false);
          });
        
         }} />
         </Form.Group>
         <Form.Group>
         <Form.Label>HeartBeat End Time</Form.Label>
         <Flatpickr data-enable-time value={etime} options={opts} className='form-control' onChange={([etime])=>{
          this.setState({etime},()=>{
            this.setState({etime: moment(this.state.etime).format('HH:mm')});
          });
        
         }} />
         </Form.Group>
         </Col>
         <Col  sm={3}>
         <Form.Group>
         <Form.Label>Currency Code</Form.Label>
         <Form.Select size="sm" onChange={this.currencycodeHandler} value={this.state.currencycode} custom >
            <option value='0'>Currency Code</option>
            <option value='1'>USD</option>
            <option value='2'>ZUR</option>
            <option value='3'>ZWG</option>
            <option value='4'>GHS</option>
            <option value='5'>GBP</option>
            {/*<option value='6'>GBT</option>*/}
         </Form.Select>
         </Form.Group>
         <Form.Group>
         <Form.Label>Currency Number</Form.Label>
         <Form.Control type="text" placeholder="Currency Number...." value={this.state.currencynumber} onChange={this.currencynumberHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Country Number</Form.Label>
         <Form.Control type="text" placeholder="Country Number...." value={this.state.countrynumber} onChange={this.countrynumberHandler}/>
         </Form.Group>
         <Form.Group>
          <Form.Check id='chklock' type="checkbox"  onClick={this.multicurrencyHandler} checked={ismulticurr}  label="MultiCurrency Enabled"/>
          </Form.Group>
          <div>
          
          <Form.Check id='zig'  inline type="checkbox" disabled={!ismulticurr}  onClick={this.currencyHandler} checked={iszig} onChange={this.currencyHandler} label="ZWG"/>
            <Form.Check id='usd'  inline type="checkbox" disabled={!ismulticurr}  onClick={this.currencyHandler} checked={isusd} onChange={this.currencyHandler} label="USD"/>    
            <Form.Check id='zgbt'  inline type="checkbox" disabled={true}  onClick={this.currencyHandler} checked={iszgbt} onChange={this.currencyHandler} label="GBT"/>
          </div>
          <hr/>
          <Form.Group hidden={!iszinara}>
          <Form.Check id='chklogin' type="checkbox"  onClick={this.loginHandler} checked={islogin}  label="Zinara OAuth Feature"/>
          </Form.Group>
        
         </Col>
         <Col  sm={3}  >
         <Form.Group>
         <Form.Label>Card Service Group</Form.Label>
         <Form.Select size="sm" onChange={this.cardHandler} value={this.state.cid} custom >
            <option value='0'>Select Card Scheme</option>
            {   
              this.state.cards.map((opt)=>(
                <option value={opt.id}>{opt.name}</option>
              ))
            }
         </Form.Select>
         </Form.Group>
         <Form.Group>
         <Form.Label>Host Configuration Group</Form.Label>
         <Form.Select size="sm" onChange={this.hostHandler} value={this.state.hid} custom >
            <option value='0'>Select Host Configuration Group</option>
            {
            this.state.hosts.map((opt)=>(
            <option value={opt.id}>{opt.name}</option>
          ))
            }
         </Form.Select>
         </Form.Group>
         <Form.Group>
         <Form.Label>Promotion Configuration Group</Form.Label>
         <Form.Select size="sm" onChange={this.promoHandler} value={this.state.pid} custom >
            <option value='0'>Select Promotion Group</option>
            {
            this.state.promos.map((opt)=>(
            <option value={opt.id}>{opt.name}</option>
          ))
            }
         </Form.Select>
         </Form.Group>
         <Form.Group>
         <Form.Label>Terminal Type</Form.Label>
         <Form.Select size="sm" onChange={this.terminaltypeHandler} value={this.state.terminaltype} custom >
            <option value='0'>Select Terminal Type</option>
            <option value='1'>RETAIL</option>
            <option value='2'>BRANCH/AGENCY</option>
         </Form.Select>
         </Form.Group>
         <Form.Group>
         <Form.Label>Logout Timeout</Form.Label>
         <Form.Control type="text" placeholder="Timeout(mins)...." value={this.state.logouttime} onChange={this.logouttimeHandler}/>
         </Form.Group>
         </Col>
         </Row>
         <Button variant="info" size="sm" onClick={this.saveHandler}>SAVE</Button>{' '}
         <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
         <Button variant="outline-info" hidden={hide}  size="sm" onClick={this.deleteHandler}>DELETE</Button>{' '}
          <Button variant="outline-info" size="sm" onClick={this.findHandler}>FIND</Button>{' '}
          <Button variant="outline-info" size="sm" onClick={this.findallHandler}>FIND ALL</Button>{' '}
          <Button variant="outline-info"  size="sm" onClick={this.activateHandler}>EXPORT MERCHANT</Button>{' '}
          <Button variant="outline-info" hidden={hide} size="sm" onClick={this.csvupload}>BULK UPLOAD</Button>{' '}
          <Button variant="outline-info" hidden={hide} size="sm" onClick={this.exportallHandler}>BULK EXPORT</Button>{' '}
          <ProgressBar className={'top-5'} hidden={!showprog}  animated variant={'danger'} label={'PROCESSING RECORDS PLEASE WAIT...'} now={100} />
         </Form>

         </Card.Body>
         </Card>
         <Modal  show={this.state.toggle}
       backdrop="static"
       keyboard={false}
      >
    <Modal.Header  closeButton onClick={this.toggleModal}>
      <Modal.Title >Merchant List Result</Modal.Title>
      </Modal.Header> 
      <Modal.Body className=" pb-1 pt-2 sform">
      <React.Fragment>
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Merchant Search Results</h6></ListGroup.Item>
         {this.state.merchList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>this.merchResultHandler(e)} variant='info'>
           {item.name} 
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={this.toggleModal} >CLOSE</Button>{' '}

      </Modal.Footer>   

</Modal>
<Modal  show={this.state.csvtoggle}
       backdrop="static"
       keyboard={false}
       onEntered ={(e)=>{
        let uploadform ={
          autoProcessQueue: false,
          uploadMultiple: false,
          maxFiles: 1, 
        }
        let myDropzone = new Dropzone("#csv",{options:{uploadForm:uploadform}});
        myDropzone.options.autoProcessQueue=false;
       
       myDropzone.on("addedfile", file => {
       
         console.log(`File added: ${file.name}`);
         this.setState({showprog:true},async()=>{
         Papa.parse(file,{
          complete:(results)=>{
            console.log("csv record size: "+results.data.length);
            console.log("CSV parse done: data: "+JSON.stringify(results.data));
            this.processcsvdata(results.data);
          }
         })
        });
       });}}
       >
<Modal.Header id='csvupload'  closeButton onClick={this.togglecsv}>
      <Modal.Title >Merchants Bulk Upload</Modal.Title>
      </Modal.Header> 
      <Modal.Body className=" pb-1 pt-2 sform">
      <React.Fragment>
        <Form id={'csv'} className="dropzone" action={'/'} >
        <div > Upload csv File here</div>
        </Form>
        
         </React.Fragment>
    </Modal.Body>
    <Modal.Footer>
 
      <Button variant="info" size="sm" onClick={this.togglecsv} >CLOSE</Button>{' '}
      </Modal.Footer>   

</Modal>
</div>
         
       );
      }
  }
export default MerchantDetails;
