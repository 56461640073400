import React from 'react';
import {Form,Button,Card,Row,Container,Col,Navbar,Spinner} from 'react-bootstrap';
import {ShowMsg} from './components/pop';
import Axios from 'axios';
import {Navigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import {Url} from './components/url';
class LoginForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userId: '',
      userPass:'',
      redirect:false,
      login:false,
      showSpin:false,
      entries:{},
      changes:{},
      views:{},
      gotroles:false,
      roleid:'',
    }
  }

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  };

  clearRoles =()=>{
    this.setState({entries:{},changes:{},views:{}})
    console.log("Views.user: "+this.state.views.user);
  }

  getRolesHandler = ()=>{
    // await timeout(2000);
     var data={roleid: sessionStorage.getItem('role')};
        var url =Url+'/getroles';
        console.log("geroles url: "+url);
     console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
     Axios.post(url,data).then((res)=>{
       console.log('get roles Response received: '+JSON.stringify(res.data));
       if(res.data.msg==='ok'){
           console.log('profile data received OK!!');
           if(res.data.roles.length>0){
           this.setState({gotroles:true,entries:res.data.roles[0],changes:res.data.roles[1],views:res.data.roles[2]});
           sessionStorage.setItem('entries',JSON.stringify(res.data.roles[0]));
           sessionStorage.setItem('changes',JSON.stringify(res.data.roles[1]));
           sessionStorage.setItem('views',JSON.stringify(res.data.roles[2]));
           console.log("Login entries: "+sessionStorage.getItem('entries'));
           
         }else{
           this.clearRoles();
         }
   
       }
     });
   };
   

  userIdHandler = event=>{
    this.setState({
      userId: event.target.value
    })
  }
  passwordHandler =event=>{
    this.setState({
      userPass:event.target.value
    })
  }

  loginHandler=(e)=>{
    e.preventDefault();
    var url =Url+'/auth'
    console.log('Doing Login POST here!!!');
    this.setState({showSpin:true});
    Axios.post(url,{
      uid: this.state.userId,
      upass:this.state.userPass
    }).then(async (res)=>{
      console.log('Response received: '+res.data.msg);
        this.setState({showSpin:false});
      if(res.data.msg==='ok'){
        console.log("userdata: "+JSON.stringify(res.data));
        sessionStorage.setItem('ndiani', res.data.id);
        sessionStorage.setItem('role',res.data.roleid);
        await this.pause2Seconds();
        sessionStorage.setItem('zita',res.data.name);
        console.log("user name: "+sessionStorage.getItem('zita') + 'Role: '+sessionStorage.getItem('role'));
       this.props.onLogin(true);
       this.setState({login:true,roleid:res.data.id});
       //this.updateProfiles(res);
       
      
      // this.getRolesHandler();

      }else{
        this.props.onLogin(false);
        ShowMsg(res.data.pop,false);
        sessionStorage.clear();
      }
    });
  }

  updateProfiles = async (res)=>{
    
  }

  pause2Seconds = () =>{
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('resolved');
      }, 500);
    });
  }

  signupHandler =()=>{
    console.log("going for redirect here!!!");
    this.setState({ redirect: true });
  }

  clearForm=()=>{
    this.setState({
      userId:'',
      userPass:'',
      redirect:false,
      login:false,
      showSpin:false
    })
  }

showSpinDiag =()=>{
const {showSpin}= this.state;
if(showSpin){
  return <span><Spinner animation="border" variant="danger" /> Please wait...</span>;
}
}

  render(){
    const id = sessionStorage.getItem('ndiani');
    if(id !== null){
      this.props.onLogin(true);
       this.setState({login:true}); 
    }
    const {redirect,login} = this.state;
    if(login){
      return(
        <Navigate to={'/'}  state={{loggedin:true}} replace />
      )
    }
    if(redirect){
    return (
      <Navigate to='/signup' />
    )
    }
    return (
    <Container className=" App-pos">
    <Row>
    <Col sm={4}></Col>
    <Col sm={4}  className="text-center"><span className="App-header shadow-sm"><FeatherIcon icon="unlock"/>OPEN TMS PORTAL</span></Col>
    <Col sm={4}></Col>
    </Row>
    <Row>
    <Col sm={4}></Col>
    <Col sm={4}>
    <Card className="shadow-sm bg-white rounded pb-5">
    <Card.Body>
    <Form>
    <Form.Group>
    <Form.Label>User Name</Form.Label>
    <Form.Control type="text" placeholder="User Name..." value={this.state.userId} onChange={this.userIdHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>User Password</Form.Label>
    <Form.Control type="password" placeholder="User Password..." value={this.state.userPass} onChange={this.passwordHandler}/>
    </Form.Group>
     {this.showSpinDiag()}
     <div className='d-grid gap-2 pt-3'>
    <Button  variant="info" size="sm" onClick={this.loginHandler} >LOGIN</Button>
    </div>
    {/*<Button variant="link" size="sm" >Sign Up</Button><span>|</span><Button variant="link" size="sm">Forgot Password?</Button>*/}
    </Form>
    </Card.Body>
    </Card>
    </Col>
    <Col sm={4}></Col>
    </Row>
    <Navbar fixed="bottom"  className="pt-3" bg="light">
    <Container fluid>
    <Navbar.Text>
    <span>&copy;{ new Date().getFullYear()>2024?'2024-'+new Date().getFullYear():''+new Date().getFullYear()} <a href="https://www.zb.co.zw">zb.co.zw</a></span>
    </Navbar.Text>
    </Container>
    </Navbar>
    </Container>
  );
  }
}

export default LoginForm;
