import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col,Spinner} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg} from './pop';
import {Url} from './url';
class OsUpdate extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        rid : 1,
        file: null,
        version:'',
        showSpin:false,
        name:'',
        otaname:'',
        export:false,
      }
    }

  isvalidatedOk =()=>{
    if(this.state.version.length===0 ||  this.state.name.length===0 )
        return false;
        return true;
  }

  handleChange =(file)=>{
      console.log('Files.name: '+file.name);
        if(file.name.startsWith('a52_')){
          var ver = file.name;
          ver = ver.replace('.zip','');
          this.setState({otaname: file.name,version:ver})
        }
    this.setState({file:file})

  }

saveHandler =()=>{
  if(this.state.file===null){
  ShowMsg('Please Upload firmware update file as required!!',false);
  return;
  }
  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg("Invalid Firmware formats selected!! ",false);
    return;
  }
  
  this.setState({showSpin:true},()=>{
  var url =Url+'/addfirmware';
  var sgrp = this.state;
  sgrp.export=false;
  console.log('Doing firmware without export POST here!!!');
  Axios.post(url,sgrp).then((res)=>{
    this.setState({showSpin:false});
    console.log('Firmware Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Firmware Update Uploaded and saved OK!! received note: '+res.data.pop);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Failed to save firmware data!!!')
      ShowMsg(res.data.pop,false);
    }
    this.clearHandler();
  });
  
});
}

clearHandler =()=>{
  this.setState({otaname:'',file:null,name:'',version:'',export:false,showSpin:false,});
}

otanameHandler =(e)=>{
  this.setState({name: e.target.value});
}

versionHandler =(e)=>{
  this.setState({version: e.target.value});
}

saveexportHandler =(e)=>{
  
  this.setState({export:true},()=>{
    if(this.state.file===null){
      ShowMsg('Please Upload Firmware Update file as required!!',false);
      return;
      }
      if(!this.isvalidatedOk()){
        //todo add msgbox
        ShowMsg('Invalid Firmware formats selected!!',false);
        return;
      }
      this.setState({showSpin:true},()=>{
        var url =Url+'/addfirmware';
        var sgrp = this.state;
        console.log('Doing firmware group POST here!!! sgGroup: '+sgrp.export);
        Axios.post(url,sgrp).then((res)=>{
          this.setState({showSpin:false});
          console.log('Sgrp Response received: '+res.data.msg);
          if(res.data.msg==='ok'){
              console.log('Firmware Group saved OK!! received note: '+res.data.pop);
              ShowMsg(res.data.pop,true);
          }else{
            console.log('Failed to save firmware group data!!!')
            ShowMsg(res.data.pop,false);
          }
          this.clearHandler();
        });
      });
      
  });
 
}

showSpinDiag =()=>{
  const {showSpin}= this.state;
  if(showSpin){
    return <span><Spinner animation="border" variant="danger" />Uploading Please wait...</span>;
  }
  }

    render(){
     return(
       <Container  className="pb-4" fluid>
       <Row>

       <Col  sm={5}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Firmware Group Entry</span></Card.Header>
       <Card.Body>
       <Form>

       <Form.Group>
       <Form.Label>Firmware Group Name</Form.Label>
       <Form.Control type="text" placeholder="Firmware Group Name...." value={this.state.name} onChange={this.otanameHandler}/>
       </Form.Group>

       <Form.Group>
       <Form.Label>Firmware OTA File</Form.Label>
       <FileInput
       className={'form-control'}
       name={'apps'}
        multiple={false}
        onDone={this.handleChange}
          />
       </Form.Group>
       <Form.Group>
       <Form.Label>Firmware Name</Form.Label>
       <Form.Control type="text" placeholder="Firmware OTA Update Name...." value={this.state.otaname}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Firmware Version</Form.Label>
       <Form.Control type="text" placeholder="Version...." value={this.state.version} />
       </Form.Group>
       {this.showSpinDiag()}
       <Button variant="info" size="sm" onClick={this.saveexportHandler}>SAVE & EXPORT TO ALL</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.saveHandler}>SAVE ONLY (NO EXPORT)</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       </Form>

       </Card.Body>
       </Card>
       </Col>
       <Col  sm={7}>

       </Col>
       </Row>
       </Container>
     )
   }

  }
  export default OsUpdate;
