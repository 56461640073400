import {useState,useEffect,useLayoutEffect,React} from 'react';
import {Tabs,Table,Tab,Form,Button,Card,Row,Container,Col,Modal, CardFooter,Spinner, CardTitle} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {Msg, ShowMsg} from './pop';
import {Url} from './url';
import FeatherIcon from 'feather-icons-react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { io } from "socket.io-client";
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter,selectFilter  } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider,{CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { formatDate, toDate } from 'date-utils-2020'
const url ='http://10.0.0.14:7999';
//console.log("Before Socket connect here!!!");
  var socket;
  var refreshInterval;
function TerminalList(...props){
  const [pos,setPos]=useStateWithCallbackLazy([]);
  const [terminals,setTerminals]=useState([]);
  const [pages,setPages]=useState(0);
  const [rows,setRows] =useState(props.rows);
  const [sdata,setSdata]=useState('');
  const [isFirstTime,setFirsttime]=useState(true);
  const [isOnline,setOnline]=useState(true);
  const [tid,setTid]=useState(0);
  const [cpos,setCPos]=useState({});
  const [toggle,setToggle]=useState(false);
  const [sid,setSid]=useState('');
  const [softlist,setSoftList]=useState([]);
  const [options,setOptions]=useState([]);
  const [configs,setConfigs]=useState({base:false,payment:false,download:false});
  const[apps,setApps]=useState({});
  const [bins,setBins]=useState(false);
  const [hide,setHide]=useState(true);
  const [info,setInfo]=useState('Exporting Updates. Please wait...');
  const [anim,setAnim]=useState('border');
  const [color,setColor]=useState('danger');
  const [batlevel,setBatLevel]=useState('0%');
  const[ptid,setPtid]=useState('');
  var tm ={};




   const socketClientInit =()=>{

     console.log("Doing Socket Registration!!");
     socket.on('connect',()=>{
       console.log("client connected!! OK");
       socket.emit('joinRoom',{ptid:'tmsportal',roomname:'tms'})
     })



     socket.on('getApps',async(soft)=>{
      console.log("Received apps here: "+JSON.stringify(soft));
      setApps({...apps,name:soft.name});
      setApps({...apps,appmgr:soft.appmgr});
      setApps({...apps,payment:soft.payment});
      setApps({...apps,installer:soft.installer});
      setApps({...apps,tmsservice:soft.tmsservice});
     })

     socket.on('connect_error',()=>{
       console.log("getting a connect error here!!");
       //socket.disconnect()
     })

     socket.on('posinfo',(data)=>{
      console.log("received updated ps data: "+JSON.stringify(data));
      console.warn("Current Battery level: "+data.batt);
      setBatLevel(data.batt);
     })

     socket.on('feedbackinfo',(data)=>{
      console.log("received feedback data: "+JSON.stringify(data));
      console.warn("Received Message: "+data.msg);
      setInfo(data.msg);
      setAnim('grow')
      setHide(false);
     })

     socket.on("disconnect", () => {
       console.log("client disconnected here!!");
     console.log(socket.connected); // false
     //socket.connect();
   });
     socket.on('message',(dat)=>{
       console.log('Event=>message: here: '+ JSON.stringify(dat));
        socket.emit("chat",{msg:'online'});
     });

     socket.on('listUpdate',(dat)=>{
       console.log('Pos List here: '+ JSON.stringify(dat));
          let tmp =[];
          let days ='';
          while(pos.length){
            pos.pop();
          }
          //setPos([]);
          console.log("pos size: "+pos.length);
       if(dat.length>0){
         for(var i=0; i<dat.length; i++){
           if(dat[i].lastupdate !== ''){
             //process time passed since last update here
             days =getLastupdateNote(dat[i].lastupdate);
             console.log("Last update note: "+days);
           }else{
             days="Never";
           }
         tmp.push({ptid:dat[i].ptid,model:dat[i].model,status:dat[i].status,lastupdate:days});
       }
     }
     if(pos.length)
     console.log("pos[0].ptid: "+ pos[0].lastupdate);
     setPos(tmp,()=>{
       console.log("done assigning: "+pos.model);
     });
     });


     //looking for online status of all devices
     socket.on('chat',(dat)=>{
       console.log('Received response here!!: '+ JSON.stringify(dat));
       const cmd={msg:'online'}
       socket.emit('chat',cmd);
     });



     socket.on('ping',(dat)=>{
        console.log("received ping: "+JSON.stringify(dat));
        pingHandler();
     });
   }

   const getLastupdateNote =(updateDate)=>{

     const oldDate = new Date(updateDate);
     console.log("old date: "+oldDate);
     const now = new Date();
     console.log("Now: "+now);
      var diff = (now-oldDate);
     diff = Math.abs(Math.floor(diff));;
     console.log("time diff: "+diff);

     // math
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if(days>0)
    {
      return days +" days ago";
    }
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if(hours>0){
      return hours + "hours ago";
    }
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if(minutes>0){
      return (minutes<2)? minutes + " minute ago":minutes + " minutes ago";
    }
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);
    if(seconds<10){
      return "just now";
    }else if(seconds> 10){
      return "few moments ago";
    }


   }

   const refreshHandler =()=>{

     const cmd={cmd:0x01,ptid:'*',msg:'online'};
    console.log("refresh handler clicked: msg: "+JSON.stringify(cmd));
     socket.emit('ping',cmd,(data)=>{
       console.log("message received after emit: "+data);
       if(!data){
         refresh();
       }
     });
   }

  const findHandler =(e)=>{
    e.preventDefault();
    return;
  }

  const filterHandler =(e)=>{
    const val = e.target.value;
    setSdata(val);
    return;
}

const getLastUpdate=(val)=>{

}

const getStatus= (s)=>{
  if(s === '')
  return 'offline';
  else if(s==='online' || s.startsWith('Download'))
  return 'online';
  else
  return s;

}


const toggleModal =()=>{
  setToggle(!toggle);
}

const refresh =()=>{
  var url =Url+'/getterminals';
  const data={};
  console.log("Doing Terminal refresh here!!!");
  Axios.post(url,data).then((res)=>{
    console.log('Terminals refresh Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Export OK!!'+ JSON.stringify(res.data.list));
        setTerminals(res.data.list);
        console.log("terminals size: "+res.data.list.length);
        setPos(res.data.list);
        //const ps =res.data.list;

    }else{
      console.log('Terminal Export failed!!!');
    }
  });

}

const pingHandler = ()=>{
  const cmd ={
   msg:'online'
  };
  console.log("##Data to send: "+JSON.stringify(cmd));
socket.emit('ping',cmd);
}

const RemoteViewerHandler = (e)=>{
  const id = e.target.id;
  setTid(id);
  const pid = pos[id].ptid;
  setPtid(pid);
  console.log("terminal id: "+id+" ptid: "+pid);
  console.log("Sending pos data request here!!!!");
  socket.emit('termdata',{ptid:pid});
  toggleModal();
  setHide(true);
  setInfo('Exporting Updates. Please wait...');
  setAnim('border')
 
}

const updateHandler =(e)=>{
  const id = e.target.id;
  setTid(id);
  const ptid = pos[id].ptid;
  console.log("terminal id: "+id+" ptid: "+ptid);
  const cmd={
    ptid:ptid,
  }
  console.log("Data to send: "+JSON.stringify(cmd));
socket.emit('update',cmd);
}

//TODO: on start
//refresh();
const sockDisconnect =()=>{
  console.log("running socket disconnect here!!");
    //socket.disconnect();
}


useEffect(()=>{
  socket = io(Url);
  socket.open();
  socketClientInit();
  GetAppsHandler();
    refreshInterval = setInterval(() => {
      socket.emit('ping',{msg:'online'});
    }, 10000);
  return (()=>{closeEvents()});
},[]);

const closeEvents=()=>{
  clearInterval(refreshInterval);
  socket.close();
}

const GetAppsHandler = async () =>{
  var data={};
  var url =Url+'/getsoftwaregrps';
 // console.log("geroles url: "+url);
console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
Axios.post(url,data).then((res)=>{
 //console.log('get roles Response received: '+JSON.stringify(res.data));
 if(res.data.msg==='ok'){
     console.log('software grps data received OK!! apps size: '+res.data.apps.length);
     setSoftList(res.data.apps);
     let apps=[];
     res.data.apps.map((app)=>{
         let option={};
         option.value=app.id;
         option.text=app.name;
         apps.push(option);
        
     }
   );
   setOptions(apps);
   }else{
    console.log("Failed to get software grp list!!");
    setSoftList([]);
   }

 
  });
 }

 const fieldHandler = (e)=>{
  const val = e.target.value;
  switch(e.target.id){
    case 'base':
      if(e.target.checked){
      setConfigs({...configs,base:true});
      }else{
        setConfigs({...configs,base:false});
      }
    break;
    case 'bins':
      if(e.target.checked){
      setBins(true);
      }else{
        setBins(false);
      }
    break;
    case 'payment':
      if(e.target.checked){
      setConfigs({...configs,payment:true});
      }else{
        setConfigs({...configs,payment:false});
      }
    break;
    case 'download':
      if(e.target.checked){
      setConfigs({...configs,download:true});
      }else{
        setConfigs({...configs,download:false});
      }
    break;
    default:
  }
}

const rebootHandler=()=>{
  const ptid = pos[tid].ptid;
  const cmd={
    ptid: ptid
  }
  socket.emit('reboot',cmd);
}

const pushHandler=()=>{
  setHide(false);
  const pid = pos[tid].ptid;
  console.log("selected PTID: "+pid);
  setPtid(pid);
  var cmd={
   action:'',
   bins:bins,
   ptid: pid,
   configs:configs
  };
  if(bins===true){
    cmd.sid=sid;
    if(sid===''){
      ShowMsg("Please Select Correct Software Group To Update!!!",false);
      setHide(true);
      return;
    }
  }
  if(!configs.base && !configs.download && !configs.payment && !bins){
    ShowMsg("Please Select one Option To Download!!!",false);
      setHide(true);
      return;
  }
  console.warn("Data to send B4 update: "+JSON.stringify(cmd));
  socket.emit('remote_push',cmd);
}

const posupdateHandler =(e)=>{
const id = e.target.id;
switch(id){
  case 'push':
  pushHandler();
  break;
  case 'reboot':
    rebootHandler();
    break;
  case 'lock':
    break;
  case 'print':
    break;
    default:

}
}


const softwaregrpHandler =(e)=>{
  console.log("Onchange here soft!!!");
  setSid(e.target.value);
  console.log("sending getApps event here!!!!")
  socket.emit('getApps',{id:e.target.value});
}


     return(
       <div  className="pb-4">
       <Row>
       <Col  sm={12}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Remote Pos Manager</span></Card.Header>
       <Card.Body>
       <Form>

       <Form.Group>
       <Form.Label>Search</Form.Label>
       <Form.Control type="text" sm={2} placeholder="Search for terminal...." value={sdata} onChange={filterHandler}/>
       </Form.Group>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>No.</th>
            <th>Pos Serial No#</th>
            <th>Model</th>
            <th>Last Update</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
          <tbody>
          {pos.map((t,idx)=>(
            <tr>
             <td>{idx+1}</td>
             <td>{t.ptid}</td>
             <td>{t.model}</td>
             <td>{t.lastupdate}</td>
             <td className="text-info"><FeatherIcon icon="disc"/> {getStatus(t.status)}</td>
             <td><Button disabled={!(t.status==='' ||t.status==='offline')?false:true} id={idx} onClick={RemoteViewerHandler} variant="outline-danger">Push Update</Button></td>
            </tr>
          ))}
          </tbody>
        </Table>
       <Button type='submit' variant="info" size="sm" onClick={findHandler} >Find Terminals</Button>{} <Button variant="outline-info" size="sm" onClick={refreshHandler}>Refresh</Button>
       </Form>

       </Card.Body>
       </Card>
       </Col>
       </Row>
       <Modal  show={toggle}
       backdrop="static"
       keyboard={false}
      >
    <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >Remote Push Viewer</Modal.Title>
      </Modal.Header> 
      <Modal.Body className=" pb-1 pt-2 ">
      <Card>
        <CardTitle className='text-center fw-light'><span >Pos Information</span></CardTitle>
      <Card.Body>
        <Form>
        <Form.Group as={Row}  controlId="">
        <Form.Label column sm="4" className=''>
          Pos Serial No:
        </Form.Label>
        <Col sm="8">
          <Form.Control className='fw-bold' plaintext readOnly defaultValue={ptid} />
        </Col>
        </Form.Group>
        <Form.Group as={Row}  controlId="">
        <Form.Label column sm="4">
          Battery Level:
        </Form.Label>
        <Col sm="8">
          <Form.Label className='fw-bold' >
            {batlevel}
          </Form.Label>
        </Col>
        </Form.Group>
        </Form>
      </Card.Body>
      </Card>
      <Card>
      <Card.Body>
        <Form>
        <Form.Group>
      <Form.Label>Software Group</Form.Label>
      <Form.Control as="select" id='soft' disabled={!bins} size="sm" onChange={softwaregrpHandler} onClick={()=>{console.log("clicked me!!")}} value={sid} custom >
         <option value='0'>Software Groups</option>
         {
           options.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }

      </Form.Control>
      </Form.Group>
      <Form.Group>
      <Form.Check id='bins' type="checkbox"  onClick={fieldHandler} checked={bins}  label="Install Software"/>
      </Form.Group>
      <Form.Group>
      <Form.Check id='base' type="checkbox"  onClick={fieldHandler} checked={configs.base}  label="Base Configurations"/>
      </Form.Group>
      <Form.Group>
      <Form.Check id='download' type="checkbox"  onClick={fieldHandler} checked={configs.download}  label="Download Config"/>
      </Form.Group>
      <Form.Group>
      <Form.Check id='payment' type="checkbox"  onClick={fieldHandler} checked={configs.payment}  label="Payment Config"/>
      </Form.Group>
      <span hidden={hide}><Spinner  animation={anim} size="sm" variant={color} /><span >{info}</span></span>
        </Form>
        </Card.Body>
        <CardFooter>
        <Button variant="info" id='push' size="sm" onClick={posupdateHandler} >Push Update</Button>{' '}
        <Button variant="outline-info" id='reboot' size="sm" onClick={posupdateHandler} >Reboot Pos</Button>{' '}
      {/* <Button variant="outline-info" id='lock' size="sm" onClick={posupdateHandler} >Pos Lock</Button>{' '}
        <Button variant="outline-info" id='print' size="sm" onClick={posupdateHandler} >Print Pos Info</Button>{' '} */}
        </CardFooter>
      </Card>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}

      </Modal.Footer>   

      </Modal>
       </div>
     )
   }


  export default TerminalList;
