import React from 'react';
import SideMenu from './sidemenu';
import Screen from './screen'
import {SignOutButton} from './buttons';
import {Row,Container,Col,Navbar} from 'react-bootstrap'
import { Navigate } from "react-router-dom";

class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        scrnIdx : -1,
        logout:false,
        zita:sessionStorage.getItem('zita'),
      }
    }

    componemtDidMount(props){
     /* window.onkeydown = (e)=>{
        if(e.ctrlKey === true){
          e.preventDefault();
        }
       }*/

       window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
      let ndiani = sessionStorage.getItem('ndiani');
     if(ndiani != null){
       this.setState({logout:false});
     }
      if(this.props.location.state.loggedin === false && ndiani === null){
        console.log("redirecting here, login not done!!!!");
        this.setState({logout:false});
      }

      console.log("this.state.zita: "+this.state.zita);
    }

    logoutHandler =()=>{
      this.setState({logout:true})
    }

    showUi =(idx)=>{
      console.log('Screen index selected: '+idx);
      this.setState({scrnIdx:idx});
    };

    render(){
      
      const {logout} = this.state;
      if(logout){
        sessionStorage.clear();
        return ( <Navigate to='/auth' />)
      }
      return(
      <div className="pb-4 dash fluid">
      <Row>
      <Col sm={12}>
      <Navbar bg="light">
      <Container fluid>
      <Navbar.Brand href="#home">
      <span className="text-info h6">Open TMS</span>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
          <SignOutButton runCode={this.logoutHandler} code={this.state.zita}/>
      </Navbar.Text>
    </Navbar.Collapse>
      </Container>
      </Navbar>
      </Col>
      </Row>
        <Row >
        <Col sm={2} className="sbar">
        <SideMenu showUi={this.showUi}/>
          </Col>
          <Col sm={10} className='dbg'>
          <Screen idx={this.state.scrnIdx} />
          </Col>
        </Row>
        <Navbar fixed="bottom"  className="pt-0" bg="light">
        <Container fluid>
        <Navbar.Text>
        <span>&copy; { new Date().getFullYear()>2024?'2024-'+new Date().getFullYear():''+new Date().getFullYear()} <a href="https://www.zb.co.zw">zb.co.zw</a></span>
        </Navbar.Text>
        </Container>
        </Navbar>
  </div>
    );
    }
}
export default Dashboard;
