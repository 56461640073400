import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col,Spinner} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg} from './pop';
import {Url} from './url';
class SoftwareGroups extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        rid : 1,
        files: null,
        appmgr:'',
        payment:'',
        tmsservice:'',
        installer:'',
        showSpin:false,
        name:'',
        export:false,
      }
    }

    componemtDidMount(props){
      /* window.onkeydown = (e)=>{
         if(e.ctrlKey === true){
           e.preventDefault();
         }
        }*/
 
        window.onkeydown = (e)=>{
         if(e.ctrlKey === true || e.metaKey === true){
           if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
           e.preventDefault();
         }
        }
      }

  isvalidatedOk =()=>{
    if(this.state.tmsservice.length===0 || this.state.payment.length===0 || this.state.name.length===0 )
        return false;
        return true;
  }

  handleChange =(files)=>{
      console.log('Files.name: '+files.name);
    files.map((file,i)=>{
        console.log('Selected file #'+(i+1), file.name);
        if(file.name.startsWith('epay')){
          this.setState({payment: file.name})
        }else if(file.name.startsWith('tmsservice')){
          this.setState({tmsservice:file.name})
        }else if(file.name.startsWith('moonplanter')){
          this.setState({installer:file.name})
        }else if(file.name.startsWith('moonlauncher')){
          this.setState({appmgr:file.name})
        }
        return 0;
    })

    this.setState({files:files})

  }

saveHandler =()=>{
  if(this.state.files===null){
  ShowMsg('Please Upload application files as required!!',false);
  return;
  }
  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg("Files with wrong formats selected!! \n\r The three files should be named as follows:\n\r 1. tmsservice-xxx \n\n 2. epay-xxx \n\n 3. moonplanter-xxx  \n\n 4. moonlauncher-xxx",false);
    return;
  }
  
  this.setState({showSpin:true});
  var url =Url+'/addsoftwaregroup';
  var sgrp = this.state;
  sgrp.export=false;
  console.log('Doing software group POST here!!!');
  Axios.post(url,sgrp).then((res)=>{
    this.setState({showSpin:false});
    console.log('Sgrp Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Software Group saved OK!! received note: '+res.data.pop);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Failed to save software group data!!!')
      ShowMsg(res.data.pop,false);
    }
    this.clearHandler();
  });
 
}

clearHandler =()=>{
  this.setState({sgrpname:'',files:null,appmgr:'',payment:'',installer:'',tmsservice:'',name:'',export:false,showSpin:false,});
}

sgrpnameHandler =(e)=>{
  this.setState({name: e.target.value});
}

saveexportHandler =(e)=>{
  
  this.setState({export:true},()=>{
    if(this.state.files===null){
      ShowMsg('Please Upload application files as required!!',false);
      return;
      }
      if(!this.isvalidatedOk()){
        //todo add msgbox
        ShowMsg('Files with wrong formats selected!! \n\r The three files should be named as follows:\n\r 1. tmsservice-xxx \n\n 2. epay-xxx \n\n 3. moonplanter-xxx  \n\n 4. moonlauncher-xxx',false);
        return;
      }
      this.setState({showSpin:true},()=>{
        var url =Url+'/addsoftwaregroup';
        var sgrp = this.state;
        console.log('Doing software group POST here!!! sgGroup: '+sgrp.export);
        Axios.post(url,sgrp).then((res)=>{
          this.setState({showSpin:false});
          console.log('Sgrp Response received: '+res.data.msg);
          if(res.data.msg==='ok'){
              console.log('Software Group saved OK!! received note: '+res.data.pop);
              ShowMsg(res.data.pop,true);
          }else{
            console.log('Failed to save software group data!!!')
            ShowMsg(res.data.pop,false);
          }
          this.clearHandler();
        });
      });
      
  });
 
}

showSpinDiag =()=>{
  const {showSpin}= this.state;
  if(showSpin){
    return <span><Spinner animation="border" variant="danger" />Uploading Please wait...</span>;
  }
  }

    render(){
     return(
       <Container  className="pb-4" fluid>
       <Row>

       <Col  sm={5}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Software Group Entry</span></Card.Header>
       <Card.Body>
       <Form>

       <Form.Group>
       <Form.Label>Software Group Name</Form.Label>
       <Form.Control type="text" placeholder="Software Group Name...." value={this.state.sgrpname} onChange={this.sgrpnameHandler}/>
       </Form.Group>

       <Form.Group>
       <Form.Label>Payment Application files</Form.Label>
       <FileInput
       className={'form-control'}
       name={'apps'}
        multiple={true}
        onDone={this.handleChange}
          />
       </Form.Group>
       <Form.Group>
       <Form.Label>Application Manager</Form.Label>
       <Form.Control type="text" placeholder="App Manager...." value={this.state.appmgr}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Payment</Form.Label>
       <Form.Control type="text" placeholder="Payment...." value={this.state.payment} />
       </Form.Group>
       <Form.Group>
       <Form.Label>Installer</Form.Label>
       <Form.Control type="text" placeholder="Installer...." value={this.state.installer} />
       </Form.Group>
       <Form.Group>
       <Form.Label>Tms Service</Form.Label>
       <Form.Control type="text" placeholder="Tms Service...." value={this.state.tmsservice} />
       </Form.Group>
       {this.showSpinDiag()}
       <Button variant="info" size="sm" onClick={this.saveexportHandler}>SAVE & EXPORT TO ALL</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.saveHandler}>SAVE ONLY (NO EXPORT)</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       </Form>

       </Card.Body>
       </Card>
       </Col>
       <Col  sm={7}>

       </Col>
       </Row>
       </Container>
     )
   }

  }
  export default SoftwareGroups;
